<template>
  <div>
    <div
        class="pa-6 py-6 d-flex flex-column fullScreenHeight"
        style="overflow-y: scroll"
    >
      <v-btn
          icon
          @click="$router.push('/menu')"
          large
          color="black"
          class="ml-n2"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <div class="text-h4 font-weight-black mb-4">{{ $t('Checkout') }}</div>
      <div
          class="mt-2"
      >
        <delivery-method-selector
            :current-delivery-method="deliveryMethod"
            @select="updateDeliveryMethod"
        />
        <current-address-display
            class="mt-4"
            @click="showAddressSelector=true"
            :address-info="addressInfo"
            :is-pick-up="isPickUp"
        />
        <div
            @click="showDateArea=!showDateArea"
            class="mt-4 d-flex align-center"
        >
          <div>
            <div class="text-caption font-weight-black">
              {{ isPickUp ? $t('PickupAt') : $t('EstimatedDeliveryTime') }}
            </div>
            <div class="text-body-1 font-weight-black">
              <v-icon
                  color="indigo darken-4"
                  small
              >mdi-clock-time-eight
              </v-icon>
              <v-icon
                  color="pink darken-4"
                  small
              >mdi-calendar
              </v-icon>
              <v-icon
                  color="black darken-4"
                  small
              >mdi-robot-love
              </v-icon>
              {{ futureWeek[selectedDay].text }}
              <span v-if="selectedTime"> , {{ selectedTime }} </span>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-icon>mdi-arrow-right</v-icon>

        </div>
      </div>
      <div class="mt-4">
        <div class="text-body-2">
          <div>
            <div class="text-caption font-weight-black mb-2">{{ $t('PricesIncludeVAT') }}</div>
          </div>
          <div class="d-flex justify-space-between">
            <span>{{ $t('TotalOf') }}:</span><span>{{ totalPriceWithoutMod | priceDisplay }} </span>
          </div>

          <div
              v-if="deliveryCost > 0 && !canArriveFreePrice"
              class="d-flex justify-space-between"
          >
            <span>{{ $t('DeliveryCost') }}</span><span>{{ deliveryCost | priceDisplay }} </span>
          </div>
          <div
              v-if="deliveryCost > 0 && canArriveFreePrice"
              class=" d-flex justify-space-between"
          >
            <span>{{ $t('DeliveryCost') }}</span>
            <div> {{ $t("freeDeliveryFee") }}</div>
          </div>
          <div
              v-if="discount>0"
              class="d-flex justify-space-between"
          >
            <span>{{ $t('discount') }}:</span><span>- {{ discount | priceDisplay }} </span>
          </div>
          <div class="d-flex justify-space-between align-center font-weight-black">
            <span>{{ $t('TotalAmount') }}</span>
            <span>{{ totalPrice | priceDisplay }} </span>
          </div>
        </div>


      </div>


      <v-spacer></v-spacer>
      <div class="text-body-2 mt-2 font-weight-black">
        {{ $t('DiscountCode') }}
      </div>
      <div class="mt-1 d-flex">
        <v-text-field
            hide-details
            v-model="discountCode"
            dense
            outlined
        >
        </v-text-field>
        <v-card
            outlined
            style="border: 2px solid black"
            @click="confirmDiscount"
            class="text-caption pa-2 font-weight-black ml-2"
            elevation="0"
        >{{ $t('ValidateDiscount') }}
        </v-card>
      </div>
      <div
          class="text-caption error--text"
          v-if="voucherError"
      >
        {{ voucherError }}
      </div>
      <div class="text-body-2 mt-2 font-weight-black">
        {{ $t('ExtraDeliveryNotes') }}
      </div>
      <div class="mt-1">
        <v-textarea
            :placeholder="$t('FurtherDeliveryInstructions')"
            outlined
            hide-details
            height="90"
            color="black"
            v-model="note"
        >
        </v-textarea>
      </div>

      <v-card
          class="d-flex mt-4 align-center px-4 font-weight-black text-body-1"
          elevation="0"
          dark
          x-large
          height="56"
          @click="showPaymentMethod"
          :color="'black'"
      >
        <template v-if="loading">
          <v-progress-circular></v-progress-circular>
        </template>
        <template v-else>
          <template v-if="canOrder">
            {{ $t('Purchase') }}
            <v-spacer></v-spacer>
            <v-icon>mdi-wallet</v-icon>
          </template>
          <div
              v-else-if="!addressInfo"
          >
            {{ $t('plsEditAddress') }}
          </div>
          <template v-else-if="!selectedTime">
            <div>
              {{ $t('plsSelectReservationTime') }}
            </div>
          </template>
          <template v-else-if="!isPickUp && differentPrice< 30">
            <div>
              {{ $t('FreeDeliveryHint', {freeDeliveryPrice: priceDisplay(-differentPrice)}) }}
            </div>
          </template>


        </template>
      </v-card>
    </div>


    <v-bottom-sheet
        v-model="sheet"
        width="100%"
    >
      <v-sheet
          class="pa-4 text-body-1"
          style="position: relative"
      >
        <div class="my-2 text-body-2 font-weight-bold">
          {{ $t('PaymentMethode') }}
        </div>
        <div>
          <v-card
              @click="sendOrder(p.id,false)"
              width="100%"
              elevation="0"
              color="grey lighten-4"
              :key="p.id"
              class="pa-3 mb-2"
              v-for="p in paymentMethodWithoutPayPal"
          >
            {{ p.name }}
          </v-card>
        </div>
        <div ref="button"></div>
        <div
            class="d-flex align-center justify-center white"
            style="position: absolute;left: 0;right: 0;top: 0;bottom: 0;z-index: 2"
            v-if="loading"
        >
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <address-selector
        :init-delivery-method="deliveryMethod"
        @save="save"
        v-model="showAddressSelector"
    />
    <v-bottom-sheet
        v-model="showDateArea"
        width="100%"
    >
      <v-card
          class="pa-6"
          tile
      >
        <div class="mt-1 text-h4 mb-8 font-weight-black">
          {{ isPickUp ? $t('PickupAt') : $t('SelectDeliveryTime') }}
        </div>
        <div style="display: grid;grid-template-columns: repeat(1,minmax(0,1fr));grid-gap: 12px">
          <v-select
              outlined
              hide-details
              prepend-inner-icon="mdi-calendar"
              v-model="selectedDay"
              :items="futureWeek"
              item-text="text"
              item-value="value"
          ></v-select>
          <template v-if="showTime.length>0">
            <v-select
                outlined
                prepend-inner-icon="mdi-clock"
                hide-details
                v-model="selectedTime"
                return-object
                :items="showTime"
            ></v-select>
          </template>
          <template v-else>
            <div class="text-body-2 pa-4 font-weight-black black white--text">
              {{
                $t('SelectedTimeSpanNotAvailable', {selectedTimeSpan: futureWeek.find(it => it.value === selectedDay)?.text})
              }}
            </div>

          </template>

        </div>
        <v-btn
            :disabled="!selectedTime"
            @click="confirmDate"
            x-large
            class="mt-8 font-weight-black"
            color="black white--text"
            elevation="0"
            block
        >{{ $t('Submit') }}
        </v-btn>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet
      width="100%"
      v-model="showLessPriceDialog">
        <v-card class="pa-4">
          <div class="mb-4 font-weight-black">
            {{$t('notReachedMinimumAmount')}}
          </div>
          <v-btn @click="$router.push('/menu')" height="44px" width="100%" class="primary">{{$t('BackToAvailablePages')}}</v-btn>
        </v-card>
    </v-bottom-sheet>
  </div>

</template>
<script>
import {Cart} from '@/dataLayer/service/CartService'
import {defaultRestaurantInfo, getCurrentRestaurantInfo} from '@/dataLayer/repository/restaurantInfo'

import AddressSelector from '@/pages/widget/adress/AddressSelector'
import {checkVoucher, DeliveryMethod, submitOrder, useVoucher, voucherUsageInfo} from '@/dataLayer/service/OrderService'
import {getFutureWeek, getOpeningTimeForWeekDay, getRestaurantInfo} from '@/dataLayer/service/api'
import GlobalConfig from "@/dataLayer/repository/GlobalSettingManager"
import IKUtils from "innerken-js-utils"
import dayjs from "dayjs"
import {OrderInfo} from '@/dataLayer/repository/currentOrderState'
import {
  ContactAddress,
  firstAvailableDeliveryMethod,
  getUsableAddressList
} from '@/dataLayer/repository/localAddressInfo'
import {loadScript} from '@paypal/paypal-js'
import {setOrderForUser} from "@/dataLayer/repository/userOrder";
import DeliveryMethodSelector from "@/pages/widget/adress/DeliveryMethodSelector.vue";
import CurrentAddressDisplay from "@/pages/widget/adress/CurrentAddressDisplay.vue";
import {DishDocker} from "aaden-base-model/lib";
import Vue from 'vue'
import {Remember} from "@/locales";

const version = require('../../../package.json').version
export default {
  name: "CheckoutPage",
  components: {CurrentAddressDisplay, DeliveryMethodSelector, AddressSelector},
  data: function () {
    return {
      showLessPriceDialog: false,
      priceDisplay: Vue.filter('priceDisplay'),
      sheet: false,
      version,
      targetDay: 0,
      startPrice: 0,
      freePrice: 0,
      showDateArea: false,
      selectedDay: 0,
      futureWeek: getFutureWeek(),
      showTime: [],
      selectedTime: null,

      paymentList: [],
      voucherError: '',
      payMethodId: null,
      cart: Cart,
      restaurantInfo: defaultRestaurantInfo,
      deliveryCost: 0,
      discountStr: '',
      showAddressSelector: false,
      note: '',
      deliveryMethod: null,
      addressInfo: null,
      DeliveryMethod,
      GlobalConfig,
      loading: false,
      discountCode: '',
    }
  },
  watch: {
    selectedDay: {
      immediate: false,
      async handler(val) {
        this.targetDay = this.getTargetDay(val)
        await this.refreshShowTime()
      }
    },
    discountCode() {
      console.log('change')
      this.voucherError = ''
    }
  },
  computed: {
    differentPrice: function () {
      console.log(this.totalPriceWithoutMod,this.startPrice,'321')
      return this.totalPriceWithoutMod - this.startPrice
    },
    canArriveFreePrice: function () {
      return parseFloat(this.freePrice) !== 0 && (this.totalPriceWithoutMod - this.freePrice) > 0
    },
    dishCount: function () {
      return this.cartList.length > 0 ? this.cart.count() : 0
    },
    cartList: function () {
      return this.cart.list?.length > 0 ? this.cart.list.filter(i => i.count > 0) : []
    },
    totalPriceWithoutMod: function () {
      return this.cartList.length > 0 ? this.cart.total() : 0
    },
    totalPriceBeforeDiscount: function () {
      if (this.canArriveFreePrice) {
        return parseFloat(this.totalPriceWithoutMod)
      } else {
        return parseFloat(this.deliveryCost) +
            parseFloat(this.totalPriceWithoutMod)
      }
    },
    discount: function () {
      if (this.discountStr) {
        return DishDocker.calculateDiscountPrice(this.totalPriceBeforeDiscount, this.discountStr)
      } else {
        return 0
      }
    },
    isPickUp: function () {
      return this.deliveryMethod === DeliveryMethod.pickup
    },
    canOrder: function () {
      return this.dishCount > 0 &&
          this.addressInfo &&
          (this.isPickUp || (this.differentPrice >= 0 && this.addressInfo.addressLine1))
          && this.selectedTime

    },
    totalPrice: function () {
      return this.totalPriceBeforeDiscount - parseFloat(this.discount)
    },
    selectedPayMethod: function () {
      return this.paymentList.find(p => p.id === this.payMethodId) ?? {name: 'Nichts'}
    },
    activePayMethodIsPaypal: function () {
      return this.selectedPayMethod.name.toLowerCase().includes('paypal')
    },
    paymentMethodWithoutPayPal: function () {
      return this.paymentList.filter(p => !p.name.toLowerCase().includes('paypal'))
    },
    paypalCredentials: function () {
      const paypal = this.paypalPaymentMethod
      return paypal ? {
        sandbox: 'AbBmj6XYaYu5X42wLdIrUMYmBUWTknOO3ikhMA_OihWFBJe-D4g-AGEG-kho6ASwvEv4bNIF57XC1TeR',
        production: paypal.apiKey
      } : null
    },
    paypalPaymentMethod() {
      return this.paymentList.find(p => p.name.toLowerCase().includes('paypal'))
    },
    paypalApiKey() {
      return this.paypalCredentials?.production
    }
  },
  methods: {
    async refreshShowTime() {
      console.trace()
      this.showTime = []
      this.showTime.push(...(await getOpeningTimeForWeekDay(
          this.targetDay,
          this.deliveryMethod)))
      if (this.showTime.length > 0) {
        this.selectedTime = GlobalConfig.useDefaultTime.toString() === 'true' ? this.showTime[0] : null
      } else {
        this.selectedTime = null
        this.showDateArea = true
      }

    },
    async updateDeliveryMethod(deliveryMethod, refreshTime = true) {
      OrderInfo.deliveryMethod = deliveryMethod
      OrderInfo.address = null
      OrderInfo.deliveryRule = null
      const savedAddress = await getUsableAddressList(deliveryMethod)
      if (savedAddress.length === 0) {

        this.showAddressDialog = true
      } else {
        OrderInfo.address = savedAddress.find(it => it.isDefault) ?? savedAddress[0]
        if (deliveryMethod === DeliveryMethod.delivery) {
          OrderInfo.deliveryRule = savedAddress[0].deliveryRule
        }
      }
      this.updateAllInfo()
      if (refreshTime) {
        await this.refreshShowTime()
      }

    },
    async showPaymentMethod() {
      this.loading = true
      if (this.canOrder) {
        this.sheet = true
        await this.$nextTick()
        if (this.paypalApiKey) {
          const paypalId = this.paypalPaymentMethod?.id
          const totalPrice = this.totalPrice.toFixed(2)
          const success = (extraInfo = {}) => {
            this.sendOrder(paypalId, true, extraInfo)
          }
          try {
            this.$refs.button.innerHTML = ''
            const paypal = await loadScript({
              "client-id": this.paypalApiKey,
              currency: "EUR",
              components: "buttons,funding-eligibility",
              "enable-funding": "ideal"
            })
            await paypal.Buttons({
              style: {
                label: 'pay'
              },
              createOrder(data, actions) {
                return actions.order.create({
                  intent: 'CAPTURE',
                  application_context: {
                    brand_name: 'Aaden POS',
                    shipping_preference: 'NO_SHIPPING',
                  },
                  purchase_units: [{
                    amount: {
                      value: totalPrice,
                      currency_code: 'EUR'
                    }
                  }]
                })
              },
              async onApprove(data, actions) {
                const orderId = data.orderID
                const paymentId = data.paymentID
                const result = await actions.order.capture()
                if (result.status === "COMPLETED" || result.status === "APPROVED") {
                  success({
                    orderId, paymentId,
                    result
                  })
                } else {
                  alert("payment failed")
                }

              }
            }).render(this.$refs.button)
          } catch (e) {
            console.warn(e)
          }
        }
      } else if (this.differentPrice < 0) {
        // await this.$router.push('/menu')
        this.showLessPriceDialog = true
        console.log('wrong')
      } else if (!this.selectedTime) {
        this.showDateArea = true
      } else {
        this.showAddressSelector = true
      }

      this.loading = false


    },
    confirmDate() {
      if (this.selectedTime && this.selectedTime) {
        this.showDateArea = false
      }
    },
    async confirmDiscount() {
      const discount = await checkVoucher(this.discountCode)
      if (discount) {
        if (await voucherUsageInfo(this.discountCode) && discount?.oneTime) {
          this.discountCode = ""
          this.$nextTick(() => {
            this.voucherError = this.$t('VoucherAlrdyUsed')
          })
          return
        }
        if (this.totalPriceBeforeDiscount > discount.minPrice) {
          this.discountStr = discount.discountStr
          this.$nextTick(() => {
            if (this.discount > discount.maxPrice) {
              this.discountStr = discount.maxPrice
            }
            if (this.discount > this.totalPriceBeforeDiscount) {
              this.discountStr = this.totalPriceBeforeDiscount.toString()
            }
          })
        } else {
          this.discountCode = ""
          this.$nextTick(() => {
            this.voucherError = this.$t('VoucherMinimumActivatePrice', {minVoucherActivatePrice: discount.minPrice})

          })
        }
      } else {
        this.discountCode = ""
        this.$nextTick(() => {
          this.voucherError = this.$t('VoucherNotFound')
        })
      }
    },
    getTargetDay: function (originDay) {
      let targetWeekday = dayjs().add(originDay, 'd').day()
      if (targetWeekday === 0) {
        targetWeekday = 7
      }
      return targetWeekday
    },
    save(addressInfo) {
      OrderInfo.address = addressInfo
      if (addressInfo) {
        OrderInfo.deliveryRule = addressInfo.deliveryRule
        OrderInfo.deliveryMethod = addressInfo.deliveryMethod
      }

      this.updateAllInfo()
      this.showAddressSelector = false
    },
    updateAllInfo() {
      this.addressInfo = OrderInfo.address
      this.deliveryMethod = OrderInfo.deliveryMethod
      this.deliveryCost = 0
      this.freePrice = 0
      this.startPrice = 0
      if (OrderInfo.deliveryRule) {
        const rule = OrderInfo.deliveryRule
        if (rule.priceMod) {
          this.deliveryCost = rule.priceMod
        }
        this.startPrice = rule.startPrice
        this.freePrice = rule.freePrice
      }
      const isPickUp = OrderInfo.deliveryMethod === DeliveryMethod.pickup
      if (isPickUp) {
        this.discountStr = GlobalConfig.pickDiscountStr
      } else {
        this.discountStr = ""
      }
    },

    async sendOrder(payMethodId, paid = false, extraInfo) {
      this.loading = true
      this.payMethodId = payMethodId
      const targetInfo = new ContactAddress(this.addressInfo)
      targetInfo.note = this.note.replaceAll(`\n`, '<BR>')
          .replaceAll("'", '-')
      targetInfo.date = dayjs().add(this.selectedDay, 'd').format('YYYY-MM-DD')
      targetInfo.time = this.selectedTime
      if (this.addressInfo.addressLine1) {
        targetInfo.addressLine1 = this.addressInfo.addressLine1.replaceAll("'", '-')
      }
      if (this.addressInfo.addressLine2) {
        targetInfo.addressLine2 = this.addressInfo.addressLine2.replaceAll("'", '-')
      }
      targetInfo.metaData = {
        takeawayWebVersion: 'new',
        version,
        paid
      }
      try {
        const res = await submitOrder(this.cartList,
            Object.assign({},
                targetInfo,
                {
                  deliveryMethod: this.isPickUp ? DeliveryMethod.pickup : DeliveryMethod.delivery,
                  discountStr: this.discountStr,
                  payMethodId: this.payMethodId,
                  deliveryCost: this.canArriveFreePrice ? 0 : this.deliveryCost,
                  paid,
                  extraInfo,
                }), this.totalPrice, paid)
        await setOrderForUser(
            {
              id: res.id,
              ...JSON.parse(res.content),
              totalPrice: this.totalPrice.toFixed(2),
              note: this.note,
              deliveryCost: this.canArriveFreePrice ? 0 : this.deliveryCost,
            }
        )
        if (this.discountCode) {
          await useVoucher(this.discountCode)
        }
        this.cart.clear()
        await this.$router.push('/complete')
      } catch (e) {
        IKUtils.showError(e, 'SubmitError')
        console.log(e)
      }
      this.loading = false
    }
  },
  async mounted() {
    this.targetDay = this.getTargetDay(this.selectedDay)
    await this.updateDeliveryMethod(
        OrderInfo.deliveryMethod ?? firstAvailableDeliveryMethod(),
    )
    this.restaurantInfo = await getCurrentRestaurantInfo()
    this.paymentList = (await getRestaurantInfo(Remember.requestLanguage, 'payMethod')).filter(p => parseInt(p.isOnline) === 1)
    this.paymentList.forEach(item => {
      item.name = item.langs.name
    })


    this.updateAllInfo()
    if (this.dishCount === 0) {
      await this.$router.push('/menu')
    }
  }
}
</script>

<style scoped>

</style>
