<template>
  <div
      @click="isActive?$emit('click'):null"
      class="mb-2"
  >
    <v-card
        :disabled="!isActive"
        outlined
        height="100%"
        class="mx-4 pa-4 d-flex"
        style="border-radius: 12px;position: relative"
    >
      <div
class="flex-grow-1 overflow-hidden d-flex flex-column"
style="border-radius: 0">
        <div class="text-body-2 font-weight-black text-capitalize">
          {{dish.code ?? ''}}.{{ dish.dishName }}
        </div>
        <div

            class="text-body-2 my-2 text-no-wrap text-truncate"
            v-html="dish.dishDesc ? dish.dishDesc : 'Keine Beschreibung'"
        ></div>
        <div class="text-caption">
          {{dish?.AllergenName ?? ''}}
        </div>
        <v-spacer></v-spacer>
        <div
            style="width: fit-content"
            @click.stop="$emit('detail',dish)"
            class="text-body-1 font-weight-black d-flex align-center"
        >
          {{ dish.price |priceDisplay }}
          <v-icon
              small
              right
          >mdi-information-outline
          </v-icon>
        </div>
      </div>
      <v-card
          style="border-radius: 8px"
          class="dishImage"
          elevation="0"
          v-if="haveImage"
      >
        <v-img
            height="80"
            width="80"
            contain
            :src="dish.cloudImage"
        />
      </v-card>
      <v-card
          flat
          v-if="count>0||!isActive"
          color="grey lighten-3"
          style="border-radius: 50rem;position: absolute;bottom: 8px;right: 8px"
          class="pa-1 px-3"
      >
        <template v-if="!isActive">
          <v-icon>mdi-package-variant-remove</v-icon>
        </template>
        <template v-else>
          {{ count }}
        </template>
      </v-card>
    </v-card>

  </div>
</template>

<script>
export default {
  name: "DishCard",
  props: {
    imageRoot: {},
    dish: {},
    inCart: {
      default: false
    }, count: {}
  },
  computed: {
    isActive() {
      return this.dish.isActive === '1'
    },
    haveImage() {
      const image = (this.dish?.image ?? '').toLowerCase()
      return image.endsWith('.png') || image.endsWith('jpg') || image.endsWith('.jpeg')
    }
  },
  components: {}
}
</script>

<style scoped>

</style>
