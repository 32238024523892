var render = function render(){
  var _vm$futureWeek$find;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "pa-6 py-6 d-flex flex-column fullScreenHeight",
    staticStyle: {
      "overflow-y": "scroll"
    }
  }, [_c('v-btn', {
    staticClass: "ml-n2",
    attrs: {
      "icon": "",
      "large": "",
      "color": "black"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/menu');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-left")])], 1), _c('div', {
    staticClass: "text-h4 font-weight-black mb-4"
  }, [_vm._v(_vm._s(_vm.$t('Checkout')))]), _c('div', {
    staticClass: "mt-2"
  }, [_c('delivery-method-selector', {
    attrs: {
      "current-delivery-method": _vm.deliveryMethod
    },
    on: {
      "select": _vm.updateDeliveryMethod
    }
  }), _c('current-address-display', {
    staticClass: "mt-4",
    attrs: {
      "address-info": _vm.addressInfo,
      "is-pick-up": _vm.isPickUp
    },
    on: {
      "click": function ($event) {
        _vm.showAddressSelector = true;
      }
    }
  }), _c('div', {
    staticClass: "mt-4 d-flex align-center",
    on: {
      "click": function ($event) {
        _vm.showDateArea = !_vm.showDateArea;
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "text-caption font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.isPickUp ? _vm.$t('PickupAt') : _vm.$t('EstimatedDeliveryTime')) + " ")]), _c('div', {
    staticClass: "text-body-1 font-weight-black"
  }, [_c('v-icon', {
    attrs: {
      "color": "indigo darken-4",
      "small": ""
    }
  }, [_vm._v("mdi-clock-time-eight ")]), _c('v-icon', {
    attrs: {
      "color": "pink darken-4",
      "small": ""
    }
  }, [_vm._v("mdi-calendar ")]), _c('v-icon', {
    attrs: {
      "color": "black darken-4",
      "small": ""
    }
  }, [_vm._v("mdi-robot-love ")]), _vm._v(" " + _vm._s(_vm.futureWeek[_vm.selectedDay].text) + " "), _vm.selectedTime ? _c('span', [_vm._v(" , " + _vm._s(_vm.selectedTime) + " ")]) : _vm._e()], 1)]), _c('v-spacer'), _c('v-icon', [_vm._v("mdi-arrow-right")])], 1)], 1), _c('div', {
    staticClass: "mt-4"
  }, [_c('div', {
    staticClass: "text-body-2"
  }, [_c('div', [_c('div', {
    staticClass: "text-caption font-weight-black mb-2"
  }, [_vm._v(_vm._s(_vm.$t('PricesIncludeVAT')))])]), _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('TotalOf')) + ":")]), _c('span', [_vm._v(_vm._s(_vm._f("priceDisplay")(_vm.totalPriceWithoutMod)) + " ")])]), _vm.deliveryCost > 0 && !_vm.canArriveFreePrice ? _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('DeliveryCost')))]), _c('span', [_vm._v(_vm._s(_vm._f("priceDisplay")(_vm.deliveryCost)) + " ")])]) : _vm._e(), _vm.deliveryCost > 0 && _vm.canArriveFreePrice ? _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('DeliveryCost')))]), _c('div', [_vm._v(" " + _vm._s(_vm.$t("freeDeliveryFee")))])]) : _vm._e(), _vm.discount > 0 ? _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('discount')) + ":")]), _c('span', [_vm._v("- " + _vm._s(_vm._f("priceDisplay")(_vm.discount)) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-space-between align-center font-weight-black"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('TotalAmount')))]), _c('span', [_vm._v(_vm._s(_vm._f("priceDisplay")(_vm.totalPrice)) + " ")])])])]), _c('v-spacer'), _c('div', {
    staticClass: "text-body-2 mt-2 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('DiscountCode')) + " ")]), _c('div', {
    staticClass: "mt-1 d-flex"
  }, [_c('v-text-field', {
    attrs: {
      "hide-details": "",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.discountCode,
      callback: function ($$v) {
        _vm.discountCode = $$v;
      },
      expression: "discountCode"
    }
  }), _c('v-card', {
    staticClass: "text-caption pa-2 font-weight-black ml-2",
    staticStyle: {
      "border": "2px solid black"
    },
    attrs: {
      "outlined": "",
      "elevation": "0"
    },
    on: {
      "click": _vm.confirmDiscount
    }
  }, [_vm._v(_vm._s(_vm.$t('ValidateDiscount')) + " ")])], 1), _vm.voucherError ? _c('div', {
    staticClass: "text-caption error--text"
  }, [_vm._v(" " + _vm._s(_vm.voucherError) + " ")]) : _vm._e(), _c('div', {
    staticClass: "text-body-2 mt-2 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('ExtraDeliveryNotes')) + " ")]), _c('div', {
    staticClass: "mt-1"
  }, [_c('v-textarea', {
    attrs: {
      "placeholder": _vm.$t('FurtherDeliveryInstructions'),
      "outlined": "",
      "hide-details": "",
      "height": "90",
      "color": "black"
    },
    model: {
      value: _vm.note,
      callback: function ($$v) {
        _vm.note = $$v;
      },
      expression: "note"
    }
  })], 1), _c('v-card', {
    staticClass: "d-flex mt-4 align-center px-4 font-weight-black text-body-1",
    attrs: {
      "elevation": "0",
      "dark": "",
      "x-large": "",
      "height": "56",
      "color": 'black'
    },
    on: {
      "click": _vm.showPaymentMethod
    }
  }, [_vm.loading ? [_c('v-progress-circular')] : [_vm.canOrder ? [_vm._v(" " + _vm._s(_vm.$t('Purchase')) + " "), _c('v-spacer'), _c('v-icon', [_vm._v("mdi-wallet")])] : !_vm.addressInfo ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('plsEditAddress')) + " ")]) : !_vm.selectedTime ? [_c('div', [_vm._v(" " + _vm._s(_vm.$t('plsSelectReservationTime')) + " ")])] : !_vm.isPickUp && _vm.differentPrice < 30 ? [_c('div', [_vm._v(" " + _vm._s(_vm.$t('FreeDeliveryHint', {
    freeDeliveryPrice: _vm.priceDisplay(-_vm.differentPrice)
  })) + " ")])] : _vm._e()]], 2)], 1), _c('v-bottom-sheet', {
    attrs: {
      "width": "100%"
    },
    model: {
      value: _vm.sheet,
      callback: function ($$v) {
        _vm.sheet = $$v;
      },
      expression: "sheet"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-4 text-body-1",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('div', {
    staticClass: "my-2 text-body-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('PaymentMethode')) + " ")]), _c('div', _vm._l(_vm.paymentMethodWithoutPayPal, function (p) {
    return _c('v-card', {
      key: p.id,
      staticClass: "pa-3 mb-2",
      attrs: {
        "width": "100%",
        "elevation": "0",
        "color": "grey lighten-4"
      },
      on: {
        "click": function ($event) {
          return _vm.sendOrder(p.id, false);
        }
      }
    }, [_vm._v(" " + _vm._s(p.name) + " ")]);
  }), 1), _c('div', {
    ref: "button"
  }), _vm.loading ? _c('div', {
    staticClass: "d-flex align-center justify-center white",
    staticStyle: {
      "position": "absolute",
      "left": "0",
      "right": "0",
      "top": "0",
      "bottom": "0",
      "z-index": "2"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": ""
    }
  })], 1) : _vm._e()])], 1), _c('address-selector', {
    attrs: {
      "init-delivery-method": _vm.deliveryMethod
    },
    on: {
      "save": _vm.save
    },
    model: {
      value: _vm.showAddressSelector,
      callback: function ($$v) {
        _vm.showAddressSelector = $$v;
      },
      expression: "showAddressSelector"
    }
  }), _c('v-bottom-sheet', {
    attrs: {
      "width": "100%"
    },
    model: {
      value: _vm.showDateArea,
      callback: function ($$v) {
        _vm.showDateArea = $$v;
      },
      expression: "showDateArea"
    }
  }, [_c('v-card', {
    staticClass: "pa-6",
    attrs: {
      "tile": ""
    }
  }, [_c('div', {
    staticClass: "mt-1 text-h4 mb-8 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.isPickUp ? _vm.$t('PickupAt') : _vm.$t('SelectDeliveryTime')) + " ")]), _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "repeat(1,minmax(0,1fr))",
      "grid-gap": "12px"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "prepend-inner-icon": "mdi-calendar",
      "items": _vm.futureWeek,
      "item-text": "text",
      "item-value": "value"
    },
    model: {
      value: _vm.selectedDay,
      callback: function ($$v) {
        _vm.selectedDay = $$v;
      },
      expression: "selectedDay"
    }
  }), _vm.showTime.length > 0 ? [_c('v-select', {
    attrs: {
      "outlined": "",
      "prepend-inner-icon": "mdi-clock",
      "hide-details": "",
      "return-object": "",
      "items": _vm.showTime
    },
    model: {
      value: _vm.selectedTime,
      callback: function ($$v) {
        _vm.selectedTime = $$v;
      },
      expression: "selectedTime"
    }
  })] : [_c('div', {
    staticClass: "text-body-2 pa-4 font-weight-black black white--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('SelectedTimeSpanNotAvailable', {
    selectedTimeSpan: (_vm$futureWeek$find = _vm.futureWeek.find(function (it) {
      return it.value === _vm.selectedDay;
    })) === null || _vm$futureWeek$find === void 0 ? void 0 : _vm$futureWeek$find.text
  })) + " ")])]], 2), _c('v-btn', {
    staticClass: "mt-8 font-weight-black",
    attrs: {
      "disabled": !_vm.selectedTime,
      "x-large": "",
      "color": "black white--text",
      "elevation": "0",
      "block": ""
    },
    on: {
      "click": _vm.confirmDate
    }
  }, [_vm._v(_vm._s(_vm.$t('Submit')) + " ")])], 1)], 1), _c('v-bottom-sheet', {
    attrs: {
      "width": "100%"
    },
    model: {
      value: _vm.showLessPriceDialog,
      callback: function ($$v) {
        _vm.showLessPriceDialog = $$v;
      },
      expression: "showLessPriceDialog"
    }
  }, [_c('v-card', {
    staticClass: "pa-4"
  }, [_c('div', {
    staticClass: "mb-4 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('notReachedMinimumAmount')) + " ")]), _c('v-btn', {
    staticClass: "primary",
    attrs: {
      "height": "44px",
      "width": "100%"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/menu');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('BackToAvailablePages')))])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }